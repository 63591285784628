import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import * as React from "react";
import { ExistingPolicy } from "components/ExistingPolicies";
import { CreatePolicy } from "components/CreatePolicy";
import { TestPolicies } from "components/TestPolicies";

export const PolicyTabs = (): JSX.Element => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Create Policy" value="1" />
            <Tab label="Existing Policies" value="2" />
            <Tab label="Test Policies" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <CreatePolicy />
        </TabPanel>
        <TabPanel value="2">
          <ExistingPolicy />
        </TabPanel>
        <TabPanel value="3">
          <TestPolicies />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
