import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import PolicyIcon from "@mui/icons-material/Policy";
import GroupsIcon from "@mui/icons-material/Groups";
import CopyrightIcon from "@mui/icons-material/Copyright";

import { Outlet, useLocation, useMatch } from "react-router-dom";

const drawerWidth = 240;

const routeToTitle: { [route: string]: string } = {
  "/": "Policies",
  "/claim": "Claims",
  "/subject": "Subjects",
};

export default function PermanentDrawerLeft() {
  const resolved = useLocation();
  const match = useMatch({ path: resolved.pathname, end: true });
  const { logout } = useAuth0();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            STS Admin Portal {match && "→ " + routeToTitle[match.pathname]}
          </Typography>
          <IconButton
            size="large"
            color="inherit"
            aria-label="logout"
            onClick={() => logout()}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/"
              selected={match?.pathname === "/"}
            >
              <PolicyIcon />
              <ListItemText primary="Policies" sx={{ paddingLeft: 1 }} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/subject"
              selected={match?.pathname === "/subject"}
            >
              <GroupsIcon />
              <ListItemText primary="Subjects" sx={{ paddingLeft: 1 }} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/claim"
              selected={match?.pathname === "/claim"}
            >
              <CopyrightIcon />
              <ListItemText primary="Claims" sx={{ paddingLeft: 1 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
