import {
  useGetClaims,
  useGetCustomClaims,
  useRevokeCustomClaimMutation,
  useIssueCustomClaimMutation,
} from "services/claimServiceHooks";
import { ClaimIn } from "@pumpjackdataworks/sts-ts-client";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import BorderedSection from "./BorderedSection";
import * as React from "react";
import Copyright from "@mui/icons-material/Copyright";
import { Namespace } from "@pumpjackdataworks/sts-ts-client";
import { TextField } from "@mui/material";
import { Approval } from "@mui/icons-material";

export const MyClaims = (): JSX.Element => {
  const { data: claims } = useGetClaims();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Namespace</TableCell>
            <TableCell>Key</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(claims || {})
            .map(([k, v]) => {
              const [namespace, kout] = k.split("/");
              return { namespace: kout ? namespace : null, k: kout, v };
            })
            .filter((m) => m.namespace)
            .map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {row.namespace}
                </TableCell>
                <TableCell>{row.k}</TableCell>
                <TableCell>{new Object(row.v).toString()}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CustomClaims = () => {
  const [mineOnly, setMineOnly] = React.useState<boolean>(false);
  const { data: claims } = useGetCustomClaims(mineOnly);
  const revoke = useRevokeCustomClaimMutation();
  const issue = useIssueCustomClaimMutation();
  const [foreignKey, setForeignKey] = React.useState<string>("user_");
  const [identifier, setIdentifier] = React.useState<string>("");

  const [claim, setClaim] = React.useState<Partial<ClaimIn>>({
    ns: Namespace.Subject,
  });

  const issueClaim = () => {
    issue.mutate({
      ns: claim.ns!,
      key: claim.key!,
      value: JSON.parse(claim.value!),
      [foreignKey]: identifier,
    });
  };

  return (
    <Stack direction="column" spacing={3}>
      <>
        <FormControlLabel
          control={
            <Switch
              checked={mineOnly}
              onChange={(e) => setMineOnly(e.target.checked)}
            />
          }
          label="Mine Only?"
        />
        {(claims || []).length === 0 ? (
          <b>Nothing to display</b>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Foreign Key</TableCell>
                  <TableCell>Namespace</TableCell>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {claims?.map((claim, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {claim.user_ || claim.group_ || claim.service}
                    </TableCell>
                    <TableCell>{claim.ns}</TableCell>
                    <TableCell>{claim.key}</TableCell>
                    <TableCell>{new Object(claim.value).toString()}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => revoke.mutate(claim.id)}
                        color="primary"
                        aria-label="Delete"
                        component="label"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
      <BorderedSection icon={Copyright} title="Issue Claim">
        <Stack direction="row" spacing={3}>
          <FormControl>
            <Select
              value={foreignKey}
              onChange={(e) => setForeignKey(e.target.value as string)}
            >
              <MenuItem value="user_">User</MenuItem>
              <MenuItem value="group_">Group</MenuItem>
              <MenuItem value="service">Service</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <TextField
              label="Foreign Key"
              variant="outlined"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <Select
              value={claim.ns}
              onChange={(e) =>
                setClaim({ ...claim, ns: e.target.value as Namespace })
              }
            >
              <MenuItem value={Namespace.Subject}>sts:subject</MenuItem>
              <MenuItem value={Namespace.Action}>sts:action</MenuItem>
              <MenuItem value={Namespace.Resource}>sts:resource</MenuItem>
              <MenuItem value={Namespace.Ctx}>sts:ctx</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <TextField
              label="Key"
              variant="outlined"
              value={claim.key}
              onChange={(e) => setClaim({ ...claim, key: e.target.value })}
            />
          </FormControl>

          <FormControl>
            <TextField
              label="Value"
              variant="outlined"
              value={claim.value}
              onChange={(e) => setClaim({ ...claim, value: e.target.value })}
            />
          </FormControl>

          <IconButton
            onClick={() => issueClaim()}
            color="primary"
            aria-label="Issue"
            component="label"
            disabled={
              (identifier || "").length === 0 ||
              (claim.key || "").length === 0 ||
              (claim.value || "").length === 0
            }
          >
            <Approval />
          </IconButton>
        </Stack>
      </BorderedSection>
    </Stack>
  );
};

export const Claims = (): JSX.Element => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="My Claims" value="1" />
            <Tab label="Custom Claims" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <MyClaims />
        </TabPanel>
        <TabPanel value="2">
          <CustomClaims />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
