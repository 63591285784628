import { useMemo } from "react";
import { PolicyApi, PolicyIn } from "@pumpjackdataworks/sts-ts-client";

import { API_URL } from "services/apiUrl";
import { useAxios } from "services/useAxios";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "./queryClient";

export const usePolicyService = () => {
  const axios = useAxios();

  return useMemo(() => new PolicyApi(undefined, API_URL, axios), [axios]);
};

export const useGetPolicies = () => {
  const svc = usePolicyService();

  return useQuery({
    queryKey: ["policies"],
    queryFn: () => svc.paginatePolicyGet().then(({ data }) => data.items),
  });
};

export const useDemoCreatePolicyMutation = () => {
  const svc = usePolicyService();

  return useMutation({
    mutationFn: (policy: PolicyIn) =>
      svc.createPolicyPost(policy).then(({ data }) => data),
    onSettled: (data) => {
      queryClient.invalidateQueries("policies");
    },
  });
};

export const useTestPolicies = (policies: string[]) => {
  const svc = usePolicyService();

  return useQuery({
    queryKey: ["testPolicies"],
    queryFn: () => svc.testPolicyTestGet(policies).then(({ data }) => data),
    cacheTime: 0,
    staleTime: 0,
    refetchInterval: 1000,
    retry: true,
  });
};
