import { createRef, MutableRefObject } from "react";
import { MutationCache, QueryClient } from "react-query";

export class MutationAndErrorCache extends MutationCache {
  errorHandlerRef = createRef<(msg: string) => void>() as MutableRefObject<
    (msg: string) => void
  >;
  constructor() {
    super({
      onError: (error: any) => {
        const status = error.response.status;
        var msg;
        if (status >= 500) {
          msg =
            "STS client encountered an error that isn't your fault. Sorry! 😓";
        } else if (status === 422) {
          // invalid field data
          const detail = error.response.data.detail[0];
          msg = `Please revise the ${detail.loc[detail.loc.length - 1]}. ${
            detail.msg
          }.`;
        } else if (status === 409) {
          // conflict with existing
          const detail = error.response.data.detail;
          msg = `"${detail.name}" already exists.`;
        } else {
          return;
        }
        if (this.errorHandlerRef.current) {
          this.errorHandlerRef.current(msg);
        }
      },
    });
  }
}

export const queryClient = new QueryClient({
  mutationCache: new MutationAndErrorCache(),
});
