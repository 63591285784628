import { useMemo } from "react";
import { ClaimsApi, ClaimIn } from "@pumpjackdataworks/sts-ts-client";

import { API_URL } from "services/apiUrl";
import { useAxios } from "services/useAxios";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "./queryClient";

const STALE_MS = 10 * 60 * 1000;

export const useClaimService = () => {
  const axios = useAxios();

  return useMemo(() => new ClaimsApi(undefined, API_URL, axios), [axios]);
};

export const useGetClaims = () => {
  const svc = useClaimService();

  return useQuery({
    queryKey: ["getClaims"],
    queryFn: () => svc.claimsClaimsGet().then(({ data }) => data),
    cacheTime: STALE_MS,
  });
};

export const useGetCustomClaims = (mine: boolean) => {
  const svc = useClaimService();

  return useQuery({
    queryKey: ["getCustomClaims", mine],
    queryFn: () =>
      svc.customClaimsClaimsCustomGet(mine).then(({ data }) => data),
  });
};

export const useRevokeCustomClaimMutation = () => {
  const svc = useClaimService();

  return useMutation({
    mutationFn: (id: string) =>
      svc
        .revokeCustomClaimClaimsCustomClaimIdDelete(id)
        .then(({ data }) => data),
    onSettled: (data) => {
      queryClient.invalidateQueries("getCustomClaims");
    },
  });
};

export const useIssueCustomClaimMutation = () => {
  const svc = useClaimService();

  return useMutation({
    mutationFn: (claim: ClaimIn) =>
      svc.issueCustomClaimsCustomPost(claim).then(({ data }) => data),
    onSettled: (data) => {
      queryClient.invalidateQueries("getCustomClaims");
    },
  });
};
