import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import { useGetPolicies, useTestPolicies } from "services/policyServiceHooks";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export const TestPolicies = () => {
  const [checked, setChecked] = React.useState<string[]>([]);
  const { data: policies } = useGetPolicies();
  const { data: passed, isLoading } = useTestPolicies(checked);

  const handleToggle = function (name: string) {
    if (checked.includes(name)) {
      setChecked(checked.filter((e) => e !== name));
    } else {
      setChecked([name, ...checked!]);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={6}>
        <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
          <List dense component="div" role="list">
            {(policies || []).map((p, value) => {
              const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItem key={value} role="listitem">
                  <ListItemIcon>
                    <Checkbox
                      tabIndex={-1}
                      onClick={(e) => handleToggle(p.name)}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={p.name} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Paper>
      </Grid>
      {!isLoading && (
        <Grid container justifyContent="flex-end">
          {passed === true ? (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
            </Alert>
          ) : null}
          {passed === false ? (
            <Alert severity="error">
              <AlertTitle>Failure</AlertTitle>
            </Alert>
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};
