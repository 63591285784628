import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import { BrowserRouter as Router } from "react-router-dom";
import { Claims } from "components/Claims";
import { PolicyTabs } from "components/PolicyTabs";

export default function App() {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthLoading, isAuthenticated, loginWithRedirect]);

  if (isAuthLoading) {
    return <>auth0 loading...</>;
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PolicyTabs />} />
          <Route path="claim" element={<Claims />} />
          <Route path="subject" element={<h1>Subject</h1>} />
        </Route>
        <Route path="/callback" element={<h2>Handle auth0 callback</h2>} />
      </Routes>
    </Router>
  );
}
