import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useDemoCreatePolicyMutation } from "services/policyServiceHooks";
import { PolicyIn } from "@pumpjackdataworks/sts-ts-client";

export const CreatePolicy = () => {
  const [policy, setPolicy] = React.useState<Partial<PolicyIn>>();

  const mutation = useDemoCreatePolicyMutation();

  const onSave = () => {
    mutation.mutate({
      ...(policy as PolicyIn),
      rule: JSON.parse(policy!.rule),
      is_global: true,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <TextField
          id="new-policy-desc-textarea"
          label="Name"
          value={policy?.name}
          onChange={(e) => setPolicy({ ...policy, name: e.target.value })}
          placeholder="Policy name"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          id="new-policy-desc-textarea"
          label="Description"
          value={policy?.description}
          onChange={(e) =>
            setPolicy({ ...policy, description: e.target.value })
          }
          placeholder="Placeholder"
          multiline
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          id="new=policy-rule-textarea"
          label="Rule"
          value={policy?.rule}
          onChange={(e) => setPolicy({ ...policy, rule: e.target.value })}
          placeholder="Placeholder"
          multiline
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <label htmlFor="priority" style={{ marginRight: "1em" }}>
          Priority
        </label>
        <input
          type="number"
          name="priority"
          step="1"
          value={policy?.priority || 0}
          onChange={(e) =>
            setPolicy({ ...policy, priority: parseInt(e.target.value) })
          }
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <label htmlFor="exclusive" style={{ marginRight: "1em" }}>
          Exclusive?
        </label>
        <input
          type="checkbox"
          name="exclusive"
          checked={policy?.exclusive || false}
          onChange={(e) =>
            setPolicy({ ...policy, exclusive: e.target.checked })
          }
        />
      </Grid>

      <Grid container justifyContent="flex-end">
        <Button
          disabled={
            !policy ||
            !policy.description ||
            !policy.name ||
            !policy.rule ||
            !policy.priority ||
            !isNaN(policy.priority!)
          }
          onClick={(e) => {
            onSave();
          }}
          variant="contained"
          sx={{ marginTop: 3 }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
