import React from "react";
import ReactDOMClient from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "App";

import { QueryClientProvider } from "react-query";
import { queryClient } from "services/queryClient";

const root = ReactDOMClient.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      audience={
        process.env.REACT_APP_AUTH0_AUDIENCE || process.env.REACT_APP_BACKEND_URL!
      }
      redirectUri={window.location.origin}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);
