import { useMemo } from "react";
import axios, { AxiosInstance } from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const useAxios = (): AxiosInstance => {
  const { getAccessTokenSilently } = useAuth0();

  const instance = useMemo(() => {
    const axiosConfig = {};
    const instance = axios.create(axiosConfig);

    instance.interceptors.request.use(async (config) => {
      const apiToken = await getAccessTokenSilently();

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${apiToken}`,
      };

      return config;
    });

    return instance;
  }, [getAccessTokenSilently]);

  return instance;
};
