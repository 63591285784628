import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useGetPolicies } from "services/policyServiceHooks";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Policy as PolicyModel } from "@pumpjackdataworks/sts-ts-client";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const ExistingPolicy = () => {
  const { data: policies } = useGetPolicies();
  const [policy, setPolicy] = React.useState<PolicyModel>();

  const handleChange = (e: SelectChangeEvent) => {
    for (let p of policies || [])
      if (p.name === (e.target.value as String)) setPolicy(p);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Policy</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Name"
            value={policy?.name || ""}
            onChange={handleChange}
          >
            {(policies || []).map((p) => (
              <MenuItem key={p.name} value={p.name}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          id="policy-desc-textarea"
          label="Description"
          placeholder="Placeholder"
          multiline
          disabled
          value={policy?.description || ""}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          id="policy-rule-textarea"
          label="Rule"
          placeholder="Placeholder"
          multiline
          disabled
          value={policy?.rule || ""}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <label htmlFor="priority" style={{ marginRight: "1em" }}>
          Priority
        </label>
        <input
          disabled
          type="number"
          name="priority"
          step="1"
          value={policy?.priority || 0}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <label htmlFor="exclusive" style={{ marginRight: "1em" }}>
          Exclusive?
        </label>
        <input
          disabled
          type="checkbox"
          name="exclusive"
          checked={policy?.exclusive || false}
        />
      </Grid>
    </Grid>
  );
};
